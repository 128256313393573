<template>
  <b-custom-tabs :tabs="tabs" lazy />
</template>

<script>
import assignmentTimes from "./assignmentTimes.vue";
import branchList from "./RiskomerBranches/list.vue";
import Central from "./Central.vue";
import CampaignPaymentDiscounts from "./CampaignPaymentDiscounts.vue";
import OverdueInterest from "./OverdueInterest.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Atama Süreleri", component: assignmentTimes },
        { title: "Şubeler", component: branchList },
        { title: "Santral", component: Central },
        { title: "Ödeme Türü İndirimleri", component: CampaignPaymentDiscounts },
        { title: "Gecikme Faizi", component: OverdueInterest },
      ],
    };
  },
};
</script>
