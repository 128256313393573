<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="createBranchForm">
      <app-input
        v-model="name"
        label="Şube Adı:"
        name="Şube Adı"
        placeholder="Şube Adı"
        rules="required"
      />
      <hr>
      <app-button
        :text="updateItem ? 'Güncelle' : 'Oluştur'"
        block
        @click="updateItem ? updateBranch() : newBranch()"
      />
      <hr>
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      name: null,
    }
  },
  computed: {
    updateItem() {
      return this.$store.getters?.appSidebarItem
    },
  },
  mounted() {
    if (this.updateItem) {
      this.name = this.updateItem?.name
    }
  },
  methods: {
    newBranch() {
      this.$validate(this.$refs.createBranchForm, () => {
        this.$axios
          .post('/riskomer-branches', { name: this.name }, { loading: 'sidebar' })
          .then(res => {
            this.$store.commit('newRiskomerBranch', res.data)
            this.$store.commit('cleanAppSidebar')
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    updateBranch() {
      this.$validate(this.$refs.createBranchForm, () => {
        this.$axios
          .patch(`/riskomer-branches/${this.updateItem._id}`, { name: this.name }, { loading: 'sidebar' })
          .then(res => {
            this.$store.commit('updateRiskomerBranch', res.data)
            this.$store.commit('cleanAppSidebar')
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
  },
}
</script>

<style></style>
