<template>
  <b-col md="4">
    <app-overlay>
      <b-card title="Gecikme Faizi Oranı" class="border">
        <app-mask-input v-model.number="data.percent" name="Gecikme Faizi" input-mask="##" type="number" texticon="%" label="Gecikme Faizi:" placeholder="Yüzde giriniz.." />
        <app-button text="Güncelle" block @click="updateData" />
      </b-card>
    </app-overlay>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      _id: null,
      data: {
        percent: null,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get("/describings", { params: { key: "overdue_interest" }, loading: "table" })
        .then(({ data }) => {
          this.data = data?.data;
          this._id = data?._id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData() {
      this.$axios
        .patch(`/describings/${this._id}`, { data: this.data }, { loading: "table" })
        .then((result) => {
          this.$emitter.$emit("Notification", {
            variant: "success",
            title: "Gecikme Faizi Güncellendi",
          });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata oluştu.",
          });
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
