<template>
  <b-col md="4">
    <app-overlay>
      <b-card
        title="Ödeme Türü İndirimleri"
        class="border"
      >
        <app-mask-input
          v-model.number="data.annualDownPaymentDiscount"
          name="Yıllık Peşin Ödeme İndirimi"
          input-mask="##"
          type="number"
          texticon="%"
          label="Yıllık Peşin Ödeme İndirimi:"
          placeholder="Yüzde giriniz.."
        />
        <app-mask-input
          v-model.number="data.monthlyCommitmentDiscountPercent"
          name="Taahhütlü Aylık Ödeme İndirimi"
          input-mask="##"
          type="number"
          texticon="%"
          label="Taahhütlü Aylık Ödeme İndirimi:"
          placeholder="Yüzde giriniz.."
        />
        <app-button
          text="Güncelle"
          block
          @click="updateData"
        />
      </b-card>
    </app-overlay>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      _id: null,
      data: {
        annualDownPaymentDiscount: null,
        monthlyCommitmentDiscountPercent: null,
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$axios
        .get('/describings', { params: { key: 'campaignPaymentTypes' }, loading: 'table' })
        .then(({ data }) => {
          this.data = data?.data
          this._id = data?._id
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateData() {
      this.$axios
        .patch(`/describings/${this._id}`, { data: this.data }, { loading: 'table' })
        .then(result => {
          this.$emitter.$emit('Notification', {
            variant: 'success',
            title: 'Ödeme Türü İndirimleri Güncellendi',
          })
        })
        .catch(err => {
          this.$emitter.$emit('Notification', {
            variant: 'danger',
            title: 'Hata oluştu.',
          })
          console.log(err)
        })
    },
  },
}
</script>

<style></style>
