<template>
  <validation-observer ref="centralForm">
    <b-col
      class="p-0"
      md="4"
    >
      <app-overlay>
        <b-card header-class="justify-content-between align-items-center py-1">
          <template #header>
            <h3 class="m-0">
              Santral
            </h3>
            <b-form-checkbox
              v-model="data.isCentralActive"
              class="custom-control-success"
              switch
              @change="updateCentralSettings"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </template>
          <hr class="mt-25">
          <app-input
            v-model="data.centralOfficer"
            name="Santral Yetkilisi"
            type="phone"
            label="Santral Yetkilisi:"
            placeholder="Telefon Giriniz..."
            rules="required|min:13"
          />
          <app-button
            text="Güncelle"
            block
            @click="updateCentralSettings"
          />
        </b-card>
      </app-overlay>
    </b-col>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      _id: null,
      data: {},
    }
  },
  mounted() {
    this.getCentralSettings()
  },
  methods: {
    getCentralSettings() {
      this.$axios
        .get('/describings', { params: { key: 'central' }, loading: 'table' })
        .then(({ data }) => {
          this._id = data._id
          this.data = data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateCentralSettings() {
      this.$validate(this.$refs.centralForm, () => {
        this.$axios
          .patch(`/describings/${this?._id}`, { data: { ...this?.data, centralOfficer: this.data.centralOfficer.replace(/\D/g, '') } }, { loading: 'table' })
          .then(({ data: { data } }) => {
            data.centralOfficer = data.centralOfficer.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')
            this.data = data
            this.$emitter.$emit('Notification', {
              variant: 'success',
              title: 'Santral Güncellendi',
            })
          })
          .catch(err => {
            this.$emitter.$emit('Notification', {
              variant: 'danger',
              title: 'Hata oluştu.',
            })
            console.log(err)
          })
      })
    },
  },
}
</script>

<style></style>
