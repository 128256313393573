<template>
  <app-overlay>
    <searchable-table
      :items="branches"
      :fields="fields"
      hover
      @row-clicked="updateBranch"
    >
      <template #modalbutton>
        <app-button
          text="Şube Ekle"
          icon="PlusIcon"
          size="md"
          @click="$showAppSidebar('Şube Ekle', branchForm)"
        />
      </template>
      <template #actions="{ item }">
        <app-icon-button
          variant="gradient-danger"
          icon="TrashIcon"
          @click="deleteBranch(item)"
        />
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import branchForm from './branchForm.vue'

export default {
  data() {
    return {
      branchForm,
      fields: [
        { key: 'name', label: 'Şube Adı' },
        { key: 'actions', label: 'Eylemler' },
      ],
    }
  },
  computed: {
    branches() {
      return this.$store.getters.riskomerBranches
    },
  },
  mounted() {
    this.getBranches()
  },
  methods: {
    getBranches() {
      this.$axios
        .get('/riskomer-branches', { loading: 'table' })
        .then(res => {
          this.$store.commit('setRiskomerBranches', res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateBranch(item) {
      this.$showAppSidebar('Şube Düzenle', branchForm, { ...item })
    },
    deleteBranch({ _id }) {
      this.$confirm({ message: 'Şubeyi silmek istediğinize emin misiniz?' }, () => {
        this.$axios
          .delete(`/riskomer-branches/${_id}`, { loading: 'table' })
          .then(res => {
            this.$store.commit('deleteRiskomerBranch', _id)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
  },
}
</script>

<style></style>
