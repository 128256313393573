<template>
  <b-card class="d-flex">
    <app-overlay>
      <app-table
        :items="items"
        :fields="fields"
      >
        <template
          slot="role"
          slot-scope="data"
        >
          {{ $physicianRole(data.item.role) }}
        </template>
        <template
          v-for="(item, key) in riskStateFields"
          :slot="item.key"
          slot-scope="data"
        >
          <b-col
            :key="key"
            class="p-0 d-flex align-items-center"
            lg="8"
            xl="7"
          >
            <app-input
              v-model.number="data.item[data.field.label].time"
              :name="item.label"
              right-text-icon="Dk."
            />
          </b-col>
        </template>
        <template #actions="{ item }">
          <app-button
            text="Güncelle"
            icon="RefreshCwIcon"
            @click="updateTimes(item)"
          />
        </template>
      </app-table>
    </app-overlay>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      riskStateFields: [
        { key: 'lowDanger', label: 'Az Tehlikeli' },
        { key: 'danger', label: 'Tehlikeli' },
        { key: 'highDanger', label: 'Çok Tehlikeli' },
      ],
    }
  },
  computed: {
    fields() {
      return [{ key: 'role', label: 'Görevi' }, ...this.riskStateFields, { key: 'actions', label: 'Eylemler' }]
    },
    assignmentTime() {
      return data => {
        const assignmentTime = data.item[data.field.label].time
        return assignmentTime || 0
      }
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.$axios
        .get('/assignment-times', { loading: 'table' })
        .then(response => {
          this.items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateTimes(item) {
      this.$axios
        .patch(
          '/assignment-times/update-many',
          {
            items: this.setUpdateItems(item),
          },
          { loading: 'table' },
        )
        .then(response => {
          console.log(response.data)
          this.$emitter.$emit('Notification', {
            title: 'İşlem Başarılı',
            variant: 'success',
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    setUpdateItems(item) {
      return Object.keys(item)
        .filter(key => key !== 'role')
        .map(key => ({ ...item[key] }))
    },
  },
}
</script>

<style></style>
